<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="organization">
        <div class="man-title">
          <div>管网设备绑定</div>
          <!-- <div>
            <el-button @click="add" type="primary" size="small">新增设备</el-button>
          </div> -->
        </div>
        <div class="table-tree">
          <div class="table">
            <el-table :data="tableData" style="width: 100%" v-loading="tableLoading" element-loading-spinner="el-icon-loading">
              <el-table-column prop="waterworks" label="管网区划">
                <template slot-scope="scope">
                  {{ scope.row.waterworks?scope.row.waterworks:'-' }}
                </template>
              </el-table-column>
              <el-table-column prop="position" label="管线位置">
                <template slot-scope="scope">
                  {{ scope.row.position?scope.row.position:'-' }}
                </template>
              </el-table-column>
              <el-table-column prop="equipmentName" label="设备名称"></el-table-column>
              <el-table-column prop="eid" label="设备号"></el-table-column>
              <el-table-column prop="digitalTwin" label="绑定状态" v-if="deviceTypeCode=='Gateway'">
                <template slot-scope="scope">
                  <el-tag size="small" type="warning" v-if="!scope.row.bindList||scope.row.bindList.length==0">未绑定</el-tag>
                  <el-tag size="small" type="success" v-else>已绑定</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                  <el-dropdown placement="bottom-start" trigger="click">
                    <span @click.stop="">
                      <span class="el-dropdown-link">
                        操作<i class="el-icon-arrow-down"></i>
                      </span>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item><div @click="bindModel(scope.row)">绑定</div></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
            <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import page from '@/components/page.vue'
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,

      rules: {
        attributeContent: [{ required: true, message: '必填项', trigger: 'blur' }]
      },
      treeData: [
        { deviceTypeCode: 'Gateway',name: '网关' },
        { deviceTypeCode: 'GatewaySub',name: '网关子设备' },
        { deviceTypeCode: 'Direct',name: '直连设备' },
      ],
      tableLoading: false,
      tableData: [],
      deviceTypeCode: '',
      defaultProps: {
        children: 'childTree',
        label: 'name'
      },

      // 子设备绑定数据
      supplierProps: {
        children: 'childTree',
        label: 'supplier'
      },
      supplierCode: '',
      brandProps: {
        children: 'childTree',
        label: 'equipmentBrand'
      },
      equipmentBrandCode: '',
      deviceDrawer: false,
      supplierData: [],//供应商数据
      brandData: [],//品牌数据
      brandLoading: false,//品牌Loading
      tableSubDeviceLoading: false,//列表Loading
      tableDataCp: [],
      tableSelectList: [],
      deviceTableData: [],
      eid: '',//网关设备eid
      dialogVisible: false,
      abilityLoading: false,
      tagTableData: [],
      pointTableCount: 0
    }
  },
  methods: {
    detail(row) {
      this.$router.push({ name: 'iotEquipmentDetail', params: {row: row}})
    },

    // 切换字典
    treeRowClick(data) {
      this.deviceTypeCode = data.deviceTypeCode
      sessionStorage.setItem('deviceTypeCode',data.deviceTypeCode)
      this.loadEquipment(data.deviceTypeCode)
    },

    // 查看所有网关子设备
    allSubEquipment() {
      this.$router.push({ name: 'iotSubEquipment' })
    },

    // 新增设备
    add() {
      this.$router.push({ name: 'iotEquipmentAdd' })
    },
    
    // 删除设备
    dltModel(row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        this.deleteEquipment(row.eid)
      })
    },
    // 记录
    record(row) {
      this.$router.push({ name: 'iotEquipmentRecord', params: {row: row}})
    },
    // 导入点表
    handleChange(e,row) {
      let formData = new FormData();
      formData.append("eid", row.eid);
      formData.append("file", e.raw);
      
      this.loading = true
      this.$ajax.post("importPointTable", formData).then((res) => {
        this.loading = false
        this.$message.success("点表导入成功");
        this.loadEquipment(this.deviceTypeCode)
      }).catch(err=>{
        this.loading = false
      })
    },
    // 查看已导入的点表
    findPointTable(row) {
      this.dialogVisible = true
      this.pointTableCount = row.pointTableCount
      this.loadTagsList(row.eid)
    },
    // 获取Tag列表
    loadTagsList(eid) {
      this.abilityLoading = true
      this.$ajax.post("iotTagsQuery", {}, { 
        eid
      }).then(res=>{
        this.tagTableData = res.data
        this.abilityLoading = false
      }).catch(err=>{
        this.abilityLoading = false
      })
    },
    // 查看组态
    config(row) {
      this.$router.push({ name: 'iotEquipmentConfig', params: {row: row}})
    },
    // 获取设备
    loadEquipment(deviceTypeCode) {
      this.loading = true
      this.$ajax.post('deviceTypeByEquipment',{
        deviceTypeCode: deviceTypeCode,
        pageNum: this.page,
        pageSize: this.size
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },

    // 删除设备
    deleteEquipment(eid) {
      this.loading = true
      this.$ajax.post('iotEquipmentDelete', {
        eid: eid
      }).then(res => {
        this.loading = false
        this.loadEquipment(this.deviceTypeCode)
        this.$message.success('删除成功')
      }).catch(err=>{
        this.loading = false
        this.$message.error('删除失败')
      })
    },

    /** 绑定子设备 **/
    bindModel(row) {
      this.$router.push({ name: 'pipelineIotBindBind', params: {row: row}})
    },

    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadEquipment(this.deviceTypeCode)
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadEquipment(this.deviceTypeCode)
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    if(sessionStorage.getItem('deviceTypeCode')){
      let deviceTypeCode = sessionStorage.getItem('deviceTypeCode')
      this.deviceTypeCode = deviceTypeCode
      setTimeout(()=>{
        this.$refs.tree.setCurrentKey(deviceTypeCode)
      },300)
      this.loadEquipment(deviceTypeCode)
    }else{
      this.deviceTypeCode = 'Gateway'
      this.loadEquipment(this.deviceTypeCode)
    }
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.organization {
  height: 100%;
}
.man-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn {
  float: right;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  .tree {
    width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
</style>

<style lang="less">
.organization {
  .el-tree-node__content {
    height: 40px;
  }
}
.dialog-class {
  border-radius: 10px;
  .el-dialog__title {
    font-size: 17px;
    font-weight: bold;
  }
}
</style>